import { ThemeName } from "@doktor-se/bones-ui/dist/web-shared/components"
import {
  Category,
  CountryCode,
  Environment,
  LanguageCode,
  PatientInfoField,
  Platform,
  Queue,
  Role,
  Staff,
  User
} from "@doktor-se/bones-ui/dist/web-shared/types"
import { DatadogOptions } from "@doktor-se/more-rum-please"

import image from "img/doktor_bg.png"
import { PaymentMethod, QueueConversation } from "types"

import { getHelpPageLinks } from "./config/helpLinks"

const useAlternativeZone = new URLSearchParams(window.location.search).get("alt_zone")
const zone =
  (useAlternativeZone && import.meta.env.VITE_APP_SERVICE_ZONE_ALT) || import.meta.env.VITE_APP_SERVICE_ZONE || ""

export const appApiUrl: string = (import.meta.env.VITE_APP_APP_API || "") + zone
export const adaptationApiUrl: string = import.meta.env.VITE_APP_ADAPTATION_SERVICE_API || ""
export const appWsUrl: string = (import.meta.env.VITE_APP_QUEUE || "") + zone
export const deepLink: string = import.meta.env.VITE_APP_DEEP_LINK || ""
export const refreshTokenDefaultValidity = Number(import.meta.env.VITE_APP_REFRESH_TOKEN_VALIDITY) || 86400
export const appStatic: string = import.meta.env.VITE_APP_APP_STATIC

const isPlatform = (platform?: string): platform is Platform => {
  const knownPlatforms: Platform[] = ["doktor", "proximus", "doktorde"]
  return !!platform && knownPlatforms.includes(platform as Platform)
}
const platformEnv = import.meta.env.VITE_APP_PARTNER
if (!isPlatform(platformEnv)) {
  throw new Error(`Unknown platform config ${platformEnv}`)
}
export const platform: Platform = platformEnv

export const freshdeskLanguage = import.meta.env.VITE_APP_FRESHDESK_LANGUAGE || undefined

export const amplitudeApiKey: string = import.meta.env.VITE_APP_AMPLITUDE_API_KEY || ""

interface AppLanguage {
  name: string
  code: LanguageCode
  locale: string
}
export const allAppLanguages: { [key in Platform]: AppLanguage[] } = {
  doktor: [
    { name: "Swedish", code: "sv", locale: "sv-SE" },
    { name: "English", code: "en", locale: "en-GB" }
  ],
  proximus: [{ name: "English", code: "en", locale: "en-GB" }],
  doktorde: [
    { name: "German", code: "de", locale: "de-DE" },
    { name: "English", code: "en", locale: "en-GB" }
  ]
}
export const appLanguages = allAppLanguages[platform]

export const dayjsLocales: { [key in LanguageCode]: () => Promise<any> } = {
  sv: () => import("dayjs/locale/sv"),
  en: () => import("dayjs/locale/en"),
  da: () => import("dayjs/locale/da"),
  nb: () => import("dayjs/locale/nb"),
  de: () => import("dayjs/locale/de"),
  nl: () => import("dayjs/locale/nl"),
  pt: () => import("dayjs/locale/pt"),
  fr: () => import("dayjs/locale/fr")
}

export const defaultTimezone: { [key in Platform]: string } = {
  doktor: "Europe/Stockholm",
  proximus: "Europe/Stockholm",
  doktorde: "Europe/Stockholm"
}

// Please update this notion page if the feature list changes https://www.notion.so/doktorse/Platform-customization-guide-42e2d1a8fde34f44947491d4fb0611e2

export type FeatureFlags =
  | "add_profile" // Profiles can be added and/or attached to the conversation
  | "assign_to_bot" // When creating a new conversation the conversation can be assigned to the bot
  | "autoassign" // The user will automatically be assigned a new conversation from the queue if their inbox is empty.
  | "beamer" // Third party library beamer (for notifying updates) are enabled and shown in the navigation
  | "booking" // Booking system
  | "booking_groups" // support showing bookings from multiple staff members at once
  | "booking_reassign" // support reassign feature for bookings
  | "booking_unassign" // support un-assign feature for bookings
  | "booking_sidebar_new_version" // new version of the sidebar in Calendar/Booking view
  | "booking_create_with_profile" // allow selection of profile when creating a booking
  | "booking_create_personal_and_profile" // allow creating booking for profile and user
  | "bulk_messages" // Bulk messages
  | "bypass_access_rights" // Access rights checks will be disabled on the frontend
  | "call_before_snooze" // Require medops to try to call the patient before they are allowed to snooze the conversation
  | "cherrypick" // Ability to choose conversations from queues. Without this queues are hidden from the user.
  | "child" // Profiles on patients are of typ child
  | "clinic_tag" // Enables the user to tag a conversation with a clinic from a fixed set of choices.
  | "close_options" // Enable close options (a.k.a. Resolution When Done). Staff needs to choose an option before closing the conversation.
  | "close_tag" // Medops must give information before closing conversation
  | "contact_before_snooze" // Require medops to try to call or chat to the patient before they are allowed to snooze the conversation
  | "conversations_load_split" // assigned conversations are not loaded with messages. They are loaded after clicking on selected conversation.
  | "country_code_dial_dropdown" // Adds country code dropdown to the dial page
  | "create_conversation" // A new conversation can be created from the search page.
  | "create_conversation_with_profile" // Can attach a profile to the conversation when creating it
  | "create_draft_user" // A User (usually external clinics) can create a draft User in the system
  | "disable_email_password_login" // Removes alternative (email & password) login for security reasons. We need to have sso sign-in turned on in that case!
  | "forward_errors_to_logs" // Forward errors to logs in datadog
  | "freshdesk_business_unit_ticket_tag" // Will add a tag for clinic/digital to every created freshdesk ticket based on users clinic metadata
  | "freshdesk_chat" // Adds a chat button for freshdesh to the bottom right corner of the page
  | "help" // Help page is shown in the navigation.
  | "hide_patient_comments" // Hides the Comments of the patient details area
  | "hide_personal_queue_count" // Hides the queue count number over "next conversation" button in inbox
  | "hide_pnr_in_header" // Hide personal number in header
  | "itsme" // Enable the itsme login method
  | "itsme_test" // Enable the itsme login method for itsme test accounts
  | "journal" // Possibility to write and display journal notes on a conversation.
  | "language" // Shows the conversations language as specified in the metadata object on the conversation.
  | "language_queues" // Queues are filtered based on the staff's preset languages. Language info are also displayed for the conversation in the queue as a string.
  | "patient_history" // Show patient history in panel patient
  | "patient_info" // Show patient info in conversation header
  | "patient_organization" // Show if patient is connected to an organization (queue, patient info)
  | "payments" // Enable doctors to create payments for patients.
  | "pet" // Profiles on patients are of type pet
  | "pet_info" // Show pet info in conversation header
  | "phrases" // Phrases (insert prewritten phrases) enabled.
  | "phrases_conversation_language" // Temporary flag allowing use of conversation language to select phrases preselected language. Can be removed after https://favro.com/organization/11a55223f38c0efa579c5465/8399efe7a0ee24e7d8467fcf?card=Dok-7816 is done.
  | "phrases_filter_dropdown" // allow filtering phrases by digital/physical care related phrases
  | "physical_to_digital" // Show new dialog with distance to clinic based on geolocation
  | "postpone" // Enables the staff to set a postpone until time when sending conversation to a queue
  | "postpone_slots_selection" // Enables timeslot selection loaded from postpone_times conversation service
  | "postpone_to_staff" // Enable possibility to postpone a conversation to a specific staff
  | "profile_icon" // Every conversation has a profile with an icon to display. Without this the initial of the user will be displayed.
  | "profile_only" // The conversation must have a profile set ie. we dont allow conversations with no profile
  | "queue_opening_hours" // Specific queues can have opening hours set for each day of the week. If a queue is closed conversations that are sent to the queue will be postponed.
  | "new_conversation_in_inbox_notification" // Enable possibility for staff to activate a browser notification when there's a new conversation in their inbox
  | "queue_ping" // Enable possibility for staff to activate a ping that will sound when their personal queue goes from empty to one
  | "queue_pagination" // Enable pagination for queue so that only limited number of conversations are fully loaded
  | "personal_queue_select" // Allow staff member to pick from next queue
  | "to_queue_select_clinic" // Allows clinic selection when moving conversation to queue
  | "region" // Shows the patients region as specified in the metadata object on the conversation.
  | "rum_tracing" // Connect Datadog RUM with datadog traces in backend
  | "sip" // SIP calls enabled.
  | "sso" // Single sign on login enabled
  | "sms_conversation_reminder" // Allow sending SMS/Push reminder to patient if patient is offline
  | "takecare" // Take Care integration is enabled. Includes opening patient in Take Care feature
  | "video_call_background" // Enable possibility for staff to activate video background image
  | "webdoc" // Webdoc integration is enabled. Includes authentication, image uploads and open patient in webdoc features.
  | "webdoc_journal_note_send" // Enable journal note feature for Webdoc - sending to Webdoc
  | "phone_patient_search" // when search by phone number is enabled area code is added.
  | "e2ee" //End-to-end encryption feature for Proximus enabled
  | "revisits" //Doctor revisits feature enabled for Doktor.se
  | "patient_avatar" //Enable PatientAvatar component
// Please update this notion page if the feature list changes https://www.notion.so/doktorse/Platform-customization-guide-42e2d1a8fde34f44947491d4fb0611e2

const featureFlagsEnv: string = import.meta.env.VITE_APP_FEATURE_FLAGS
export const featureFlags = new Set((featureFlagsEnv?.split(",").map(f => f.trim()) || []) as FeatureFlags[])

export const themeName: ThemeName = (import.meta.env.VITE_APP_THEME as ThemeName) || "default"

export const filterPersonalQueueBasedOnRole = (conversations: QueueConversation[], user?: User) => {
  if (!user) return []
  let filteredConversations = [...conversations]

  if (featureFlags.has("language_queues") && !!filteredConversations) {
    filteredConversations = filteredConversations.filter(
      c => c.metadata?.language && user.data?.languages?.includes(c.metadata?.language)
    )
  }

  return filteredConversations
}

/*
If the partner and this role has access to the help page add this key to lokalise
label.role.[role name]
*/

const defaultRolesByPlatform: Record<Platform, { roles: Role[]; fallback: Role }> = {
  doktor: {
    roles: [
      "doctor",
      "psych",
      "midwife",
      "external",
      "external_nurse",
      "physio",
      "coord",
      "assistant_nurse",
      "external_rehab_coordinator"
    ],
    fallback: "nurse"
  },
  proximus: {
    roles: ["coord", "external", "audiologist", "psych", "rtwcoach"],
    fallback: "doctor"
  },
  doktorde: {
    roles: ["nurse"],
    fallback: "doctor"
  }
}

export const defaultRole = (userRoles: Role[]): Role | undefined => {
  if (!defaultRolesByPlatform[platform]) {
    return
  }
  const { roles, fallback } = defaultRolesByPlatform[platform]
  const firstMatchingRole = roles.find(role => userRoles.includes(role))
  return firstMatchingRole || fallback
}

const sipRegionsEnv: string = import.meta.env.VITE_APP_SIP_REGIONS
export const sipRegions = new Set(sipRegionsEnv?.split(",").map(f => f.trim()) || [])

const isEnvironment = (environment?: string): environment is Environment => {
  const knownEnvironments: Environment[] = ["development", "staging", "production", "testing", "tuv"]
  return !!environment && knownEnvironments.includes(environment as Environment)
}
const environmentEnv = import.meta.env.VITE_APP_ENVIRONMENT
if (!isEnvironment(environmentEnv)) {
  throw new Error(`Unknown environment config ${environmentEnv}`)
}
export const environment: Environment = environmentEnv

export const datadogConfig: DatadogOptions = {
  applicationId: import.meta.env.VITE_APP_DATADOG_APP_ID!,
  clientToken: import.meta.env.VITE_APP_DATADOG_TOKEN!,
  environment,
  project: platform,
  version: import.meta.env.VITE_APP_VERSION!,
  service: "carealot-ui",
  allowedTracingOrigins: featureFlags.has("rum_tracing") ? [new RegExp(`https://.*\\.${zone}`)] : undefined
}

const allConversationLimits: { [key in Platform]: { role: Role; amount: number }[] | undefined } = {
  doktor: undefined,
  proximus: undefined,
  doktorde: undefined
}

export const conversationLimit: undefined | { role: Role; amount: number }[] =
  allConversationLimits[platform] || undefined

export const approvedClinicsForClinicQueue: string[] = [
  "Rosenlunds Vårdcentral",
  "Fridhemsplans Vårdcentral",
  "Norrtälje Hälsocentral",
  "Rimbo Hälsocentral"
]

export const helpPageLinks = featureFlags.has("help") ? getHelpPageLinks() : undefined

export const getPatientInfoFields = () => {
  let fields: { profile?: PatientInfoField[]; user?: PatientInfoField[] } = { user: ["pnrAge", "phone", "email"] }
  switch (platform) {
    case "doktor":
      fields = {
        user: ["pnrAge", "phone", "email", "county"],
        profile: ["profile.pnrAge", "separator", "name", "pnrAge", "phone", "email", "county"]
      }
      break
    case "proximus":
      fields = { user: ["language", "pnr", "eid", "age", "sex", "phone", "email"] }
      break
    case "doktorde":
      fields = {
        user: [
          "header.personal",
          "dateOfBirth",
          "sex",
          "header.contact",
          "address",
          "phone",
          "email",
          "header.insurance",
          "insurance"
        ],
        profile: [
          "profile.header.personal",
          "profile.dateOfBirth",
          "profile.sex",
          "profile.address",
          "profile.header.insurance",
          "profile.insurance",
          "parent.header.personal",
          "name",
          "dateOfBirth",
          "sex"
        ]
      }
      break
    default:
      break
  }
  return fields
}

export const orderCommentAndDetails = () => {
  let sortItemList: Array<"details" | "comment"> = ["details", "comment"]
  switch (platform) {
    case "doktorde":
      sortItemList = ["comment", "details"]
      break
  }
  return sortItemList
}

interface SearchConfig {
  params: string[]
  regex?: RegExp
  format?: string
}
const allSearchConfigs: Record<Platform, SearchConfig> = {
  doktor: { params: ["pnr"] },
  proximus: { params: ["pnr"] },
  doktorde: { params: ["query"], regex: /\d{2}.\d{2}.\d{4}/ }
}
export const searchConfig: SearchConfig = allSearchConfigs[platform]

export const itsmeEnabled = featureFlags.has("itsme") || featureFlags.has("itsme_test")

const allPhrasesLanguages: { [key in Platform]: LanguageCode[] } = {
  doktor: ["sv", "en"],
  proximus: ["nl", "fr"],
  doktorde: ["de"]
}
export const phrasesLanguages = featureFlags.has("phrases") ? allPhrasesLanguages[platform] : undefined

export const deepARLicenseKey = import.meta.env.VITE_APP_DEEPAR_LICENSE_KEY
export const videoBackgroundImage = image

const blockedToFilterConversationsForRole: { [key in Platform]: Role[] } = {
  doktor: [],
  proximus: ["doctor", "external"],
  doktorde: ["doctor"]
}

export const showStaffFilterForConversations = (defaultRole?: Role) => {
  return defaultRole && !blockedToFilterConversationsForRole?.[platform]?.includes(defaultRole)
}

const blockedToSearchByPatientNameInCalendarForRole: { [key in Platform]: Role[] } = {
  doktor: [],
  proximus: [],
  doktorde: ["doctor"]
}

export const allowToSearchByPatientNameInCalendar = (defaultRole?: Role) => {
  return defaultRole && !blockedToSearchByPatientNameInCalendarForRole?.[platform]?.includes(defaultRole)
}

const blockDefaultSearchForRole: { [key in Platform]: Role[] } = {
  doktor: [],
  proximus: [],
  doktorde: []
}

export const blockDefaultSearch = (defaultRole?: Role) => {
  if (!defaultRole) return true
  return defaultRole && blockDefaultSearchForRole[platform].includes(defaultRole)
}

export const categoriesPerRole = (categories: Category[], defaultRole: Role) => {
  const notDisabledCategories = categories.filter(c => !c.disabled)
  switch (platform) {
    case "doktor": {
      if (defaultRole === "external") {
        return notDisabledCategories.filter(category => !category.metadata.conversationMetaInit?.internal)
      }
      return notDisabledCategories
    }
    case "proximus": {
      if (defaultRole === "audiologist") {
        return notDisabledCategories.filter(category => category.staffOnly)
      }
      return notDisabledCategories
    }
    default: {
      return notDisabledCategories
    }
  }
}

const denyAssignToBot: { [key in Platform]: Role[] } = {
  doktor: ["external", "external_rehab_coordinator"],
  proximus: [],
  doktorde: []
}

export const assignToBotEnabled = (defaultRole?: Role) => {
  return featureFlags.has("assign_to_bot") && !(defaultRole && denyAssignToBot[platform].includes(defaultRole))
}

const denyAssignToOtherStaff: { [key in Platform]: Role[] } = {
  doktor: ["external_rehab_coordinator"],
  proximus: ["audiologist"],
  doktorde: ["doctor"]
}

export const assignToOtherStaffEnabled = (defaultRole?: Role, draftUser?: boolean) => {
  return !(defaultRole && denyAssignToOtherStaff[platform].includes(defaultRole)) && !draftUser
}

export const assignToSelfEnabled = (draftUser?: boolean) => {
  return !draftUser
}

const denyReopenConversation: { [key in Platform]: Role[] } = {
  doktor: [],
  proximus: [],
  doktorde: ["doctor"]
}

export const reopenConversationEnabled = (defaultRole?: Role) => {
  return !(defaultRole && denyReopenConversation[platform].includes(defaultRole))
}

const denyAssignToQueue: { [key in Platform]: Role[] } = {
  doktor: [],
  proximus: [],
  doktorde: []
}

export const assignToQueueEnabled = (defaultRole?: Role) => {
  return !(defaultRole && denyAssignToQueue[platform].includes(defaultRole))
}

const hideSearchPage: { [key in Platform]: Role[] } = {
  doktor: [],
  proximus: [],
  doktorde: []
}

export const showSearchPage = (defaultRole?: Role) => {
  return !(defaultRole && hideSearchPage[platform].includes(defaultRole))
}

const hideHelpPage: { [key in Platform]: Role[] } = {
  doktor: ["external", "external_nurse", "external_rehab_coordinator"],
  proximus: [],
  doktorde: []
}

export const showHelpPage = (defaultRole?: Role) => {
  return featureFlags.has("help") && !(defaultRole && hideHelpPage[platform].includes(defaultRole))
}

const allowCreateDraftUser: { [key in Platform]: Role[] } = {
  doktor: ["external_nurse"],
  proximus: [],
  doktorde: []
}

// Conversations created with draft users can only be assigned to the chat bot
export const createDraftUserEnabled = (defaultRole?: Role) => {
  return featureFlags.has("create_draft_user") && defaultRole && allowCreateDraftUser[platform].includes(defaultRole)
}

const alwaysAllowSnoozeForRole: { [key in Platform]: Role[] } = {
  doktor: ["coord", "nurse", "assistant_nurse", "psych", "midwife", "physio", "external_nurse"],
  proximus: [],
  doktorde: ["nurse"]
}

const snoozeBlockEnabled = (featureFlag: FeatureFlags, defaultRole?: Role) => {
  return featureFlags.has(featureFlag) && !(defaultRole && alwaysAllowSnoozeForRole[platform].includes(defaultRole))
}

export const requiredActionBeforeSnooze = (defaultRole?: Role) => {
  if (snoozeBlockEnabled("call_before_snooze", defaultRole)) {
    return ["sip", "call"]
  } else if (snoozeBlockEnabled("contact_before_snooze", defaultRole)) {
    return ["chat", "sip", "call"]
  } else {
    return null
  }
}

const allowCherryPickForRole: { [key in Platform]: Role[] } = {
  doktor: ["cherry_pick", "group_leader", "coord"],
  proximus: ["cherry_pick", "coord"],
  doktorde: ["cherry_pick", "coord"]
}

export const allowCherryPick = (roles: Role[]) => {
  return allowCherryPickForRole[platform].some(a => roles.includes(a))
}

const allPaymentMethods: { [key in Platform]: PaymentMethod | "" } = {
  doktor: "",
  proximus: "payconiq",
  doktorde: ""
}
export const paymentMethod = allPaymentMethods[platform]

const blockFetchQueueConversationsForRole: { [key in Platform]: Role[] } = {
  doktor: [],
  proximus: [],
  doktorde: []
}
export const blockFetchQueueConversations = (defaultRole?: Role) => {
  if (!defaultRole) return true
  return blockFetchQueueConversationsForRole[platform].includes(defaultRole)
}

const enableAutoassignForRole: { [key in Platform]: Role[] } = {
  doktor: ["nurse"],
  proximus: [],
  doktorde: []
}

export const autoAssignFeature = (roles?: Role[]) => {
  return featureFlags.has("autoassign") && enableAutoassignForRole[platform].some(role => roles?.includes(role))
}

export const staffListForAssigning = (staff: Staff[], clinic?: string) => {
  switch (platform) {
    case "doktor": {
      if (!clinic) return staff.filter(user => !user.data?.clinic)
      return staff.filter(user => user.data?.clinic === clinic)
    }
    case "proximus": {
      if (featureFlags.has("e2ee")) {
        return staff.filter(user =>
          !!user.customerMetadata?.encryption?.eligibleForEncryption ? !!user.customerMetadata.encryption.sealdId : true
        )
      }
      return staff
    }
    default: {
      return staff
    }
  }
}

const allCallCountryCodes: { [key in Platform]: { region: CountryCode; number: string }[] } = {
  doktor: [],
  proximus: [],
  doktorde: []
}
export const callCountryCodes = allCallCountryCodes[platform]

const searchPrefixCode: { [key in Platform]: string | undefined } = {
  doktor: undefined,
  proximus: undefined,
  doktorde: "+49"
}

export const prefixCode = searchPrefixCode[platform]
export const queueTimeClass = (time: number) => {
  switch (platform) {
    case "doktorde": {
      if (time < 21) return "one"
      if (time < 51) return "two"
      return "five"
    }
    default: {
      if (time < 4) return "one"
      if (time < 10) return "two"
      if (time < 20) return "three"
      return "four"
    }
  }
}

export const defaultPageSizeForQueue = 25
export const nextPageQueueSize = 25
export type CalendarCountryCode = "se" | "gb" | "br" | "no" | "dk" | "de" | "nl" | "fr" | "za" | "be"
export const calendarCountryByPlatform: { [key in Platform]: CalendarCountryCode | null } = {
  doktor: "se",
  proximus: "be",
  doktorde: "de"
}

export const webdocAuthUrl = import.meta.env.VITE_APP_WEBDOC_AUTH_URL

export const webdocFeatureEnabled = (defaultRole?: Role) => {
  return (
    featureFlags.has("webdoc") &&
    !(defaultRole && ["external", "external_nurse", "external_rehab_coordinator"].includes(defaultRole))
  )
}

const takecareClinics = [
  "Norrtälje",
  "Rimbo Hälsocentral",
  "Husläkarna Rimbo",
  "Katarina Bangata",
  "Väsby Läkargrupp",
  "Sagoskogen",
  "Fridhemsplan",
  "Norra Djurgårdsstaden",
  "Nacka Strand",
  "Rosenlund"
]

export const isClinicUsingTakecare = (userClinic: string) =>
  takecareClinics.some(c => userClinic.toLowerCase().includes(c.toLowerCase()))

const calendarConfig: { [key in Platform]: { minCalendarEventDuration: number; minClickableAreaInCalendar: string } } =
  {
    doktor: { minCalendarEventDuration: 5, minClickableAreaInCalendar: "00:05:00" },
    proximus: { minCalendarEventDuration: 15, minClickableAreaInCalendar: "00:10:00" },
    doktorde: { minCalendarEventDuration: 5, minClickableAreaInCalendar: "00:05:00" }
  }

export const calendarEventDuration: { minCalendarEventDuration: number; minClickableAreaInCalendar: string } =
  calendarConfig[platform]

const blockCloseOptionsForRole: { [key in Platform]: Role[] } = {
  doktor: ["external", "external_nurse", "external_rehab_coordinator"],
  proximus: [],
  doktorde: []
}
export const closeOptionsFeature = (defaultRole?: Role) => {
  if (!defaultRole) return false
  return featureFlags.has("close_options") && !blockCloseOptionsForRole[platform].includes(defaultRole)
}

export const webdocFieldsMapping: { [key: string]: number | null } = {
  "journal_note.section.allergies": 8,
  "journal_note.section.current_medications": 10,
  "journal_note.section.consent": 11,
  "journal_note.section.current_status": 6,
  "journal_note.section.medical_conditions": 9
}

const doktorDeVisibleQueues = ["nurse", "doctor"]

export const filterAvailableQueuesToMoveTo = (queues: Queue[], defaultRole?: Role) => {
  switch (platform) {
    case "doktorde": {
      if (defaultRole === "doctor") {
        return queues.filter(q => doktorDeVisibleQueues.includes(q.queueKey))
      }
      return queues
    }
    default: {
      return queues
    }
  }
}
