import React, { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"

import dayjs, { Dayjs } from "dayjs"

import {
  Button,
  Dropdown,
  Radio,
  RadioGroup,
  StaffBodyTextS,
  StaffHeading3,
  StaffLabelTextS
} from "@doktor-se/bones-ui"
import { OptionProps } from "@doktor-se/bones-ui/dist/components/Dropdown/Dropdown"
import { RadioBtn } from "@doktor-se/bones-ui/dist/web-shared/components"
import { usePrevious } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { Clinic, Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import { featureFlags, filterAvailableQueuesToMoveTo } from "config"
import { useAppSelector } from "lib/hooks"
import { clinicSelectors } from "reducers/clinics"
import { queueSelectors } from "reducers/queue/queue.reducer"

import TimeInput from "components/TimeInput/TimeInput"

import { Day } from "../../ToQueueDialog"
import PostponeTimes from "./PostponeTimes"
import { useQueueIsOpen } from "./useQueueIsOpen"

import styles from "./ToQueueStaff.module.scss"

type DialogType = "digital" | "physical"

interface ToQueueStaffProps {
  selectedQueue?: Queue
  setSelectedQueue: (queue?: Queue) => void
  onCancel: () => void
  onConfirm: (selectedQueue: Queue, postponed?: string, selectedClinicId?: string) => void
  conversationId: string
}

const ToQueueStaff = ({ selectedQueue, setSelectedQueue, onCancel, onConfirm, conversationId }: ToQueueStaffProps) => {
  const intl = useIntl()

  const [time, setTime] = useState<string>("")
  const [day, setDay] = useState<Day>()
  const [dateFromSlotSelection, setDateFromSlotSelection] = useState<Dayjs>()
  const [nowSelected, setNowSelected] = useState(true)
  const { defaultRole } = useAppSelector(state => state.auth)
  const clinics = useAppSelector(clinicSelectors.selectAll)

  const queues = useAppSelector(queueSelectors.selectAll)
  const clinicOptions: OptionProps[] = clinics
    .filter(
      clinic =>
        Array.isArray(clinic.supported_queue_ids) &&
        clinic.supported_queue_ids.length &&
        clinic.supported_queue_ids.some(q => queues.find(queue => queue.id === q))
    )
    .map(clinic => ({
      id: clinic.id,
      name: clinic.name
    }))

  const [selectedClinic, setSelectedClinic] = useState<Clinic | undefined>()
  const onClinicSelect = (clinicId: string) => {
    const selected = clinics.find(c => c.id === clinicId)
    if (selected && setSelectedClinic) {
      setSelectedClinic(selected)
      setSelectedQueue(undefined)
    }
  }

  const dialogOptions = [
    {
      value: "digital",
      translationKey: "control.unassign.option.digital"
    },
    {
      value: "physical",
      translationKey: "control.unassign.option.physical"
    }
  ]

  const [dialogOption, setDialogOption] = useState<DialogType>("digital")

  const queueOpeningHours = useAppSelector(state => state.app.queueOpeningHours)
  const appLanguage = useAppSelector(state => state.app.language)

  const prevDay = usePrevious(day)

  const queueIsOpen = useQueueIsOpen(queueOpeningHours, selectedQueue)

  const getPostponedTime = () => {
    if (featureFlags.has("postpone_slots_selection")) {
      return dateFromSlotSelection?.format()
    } else {
      if (!!time) {
        let date = dayjs().format("YYYY-MM-DD")
        if (day === "tomorrow") date = dayjs().add(1, "days").format("YYYY-MM-DD")
        return dayjs(`${date} ${time}`).format()
      }
    }
  }

  const handleUnassign = () => {
    if (selectedQueue) {
      const postponedTime = getPostponedTime()
      const newClinicId = dialogOption === "physical" ? selectedClinic?.id : undefined
      onConfirm(selectedQueue, postponedTime, newClinicId)
    }
  }

  useEffect(() => {
    if (!!time) {
      const now = dayjs().format("HH:mm")
      if (time > now && (!day || (prevDay === day && day === "tomorrow"))) {
        setDay("today")
      } else if (time <= now && (!day || day === "today")) {
        setDay("tomorrow")
      }
    } else {
      setDay(undefined)
    }
  }, [time, day, prevDay, setDay])

  const handleDialogOptionChange = (dialogType: DialogType) => {
    setDialogOption(dialogType)
    setSelectedQueue(undefined)
    setSelectedClinic(undefined)
  }

  const availableQueues = useMemo(() => {
    let filtered: Queue[] = []
    if (dialogOption === "digital") {
      filtered = queues.filter(q => q.isDigital)
    }
    if (dialogOption === "physical") {
      filtered = queues.filter(q => selectedClinic?.supported_queue_ids?.includes(q.id))
    }
    filtered = filterAvailableQueuesToMoveTo(filtered, defaultRole)

    return filtered
  }, [defaultRole, dialogOption, queues, selectedClinic?.supported_queue_ids])

  const sliceQueueName = (queueName: string) => {
    if (queueName.length > 30) {
      return queueName.slice(0, 30) + "..."
    }
    return queueName
  }

  return (
    <>
      <StaffHeading3 className={styles.heading}>{intl.formatMessage({ id: "unassign.title" })}</StaffHeading3>
      {featureFlags.has("to_queue_select_clinic") && (
        <div style={{ width: "100%" }}>
          <RadioGroup
            label=""
            aria-label="Dialog Type"
            aria-labelledby=""
            defaultValue="digital"
            onChange={value => {
              handleDialogOptionChange(value as DialogType)
            }}>
            {dialogOptions.map(option => (
              <Radio key={option.value} value={option.value}>
                {intl.formatMessage({ id: option.translationKey })}
              </Radio>
            ))}
          </RadioGroup>
        </div>
      )}
      {dialogOption === "physical" && (
        <div className={styles.selectClinic}>
          <Dropdown
            variant="onSurface"
            defaultSelectedKey={selectedClinic?.id}
            label="Select clinic"
            aria-label="Select clinic"
            onSelectionChange={onClinicSelect}
            placeholder={intl.formatMessage({ id: "label.to_queue.clinic.select" })}
            items={clinicOptions}
          />
        </div>
      )}
      {(dialogOption === "digital" || (dialogOption === "physical" && selectedClinic)) &&
        (availableQueues.length ? (
          <div className={styles.queueOptions}>
            {availableQueues.map(queue => (
              <Button
                variant={queue.queueKey === selectedQueue?.queueKey ? "primary" : "outline"}
                color="accent"
                key={queue.queueKey}
                onPress={() => setSelectedQueue(queue)}>
                {sliceQueueName(queue.translations[appLanguage])}
              </Button>
            ))}
          </div>
        ) : (
          <StaffBodyTextS style={{ marginBottom: "16px" }}>
            {intl.formatMessage({ id: "conversation.to_queue.no_available_clinic_queue" })}
          </StaffBodyTextS>
        ))}
      {featureFlags.has("postpone") && (
        <>
          {!!selectedQueue && (
            <div className={styles.postpone}>
              <StaffHeading3 className={styles.heading} margin={{ bottom: "var(--size-200)" }}>
                {intl.formatMessage({ id: "postpone.title" })}
              </StaffHeading3>
              <Button
                variant={
                  (featureFlags.has("postpone_slots_selection") && !!dateFromSlotSelection) || !!time
                    ? "outline"
                    : "primary"
                }
                color="accent"
                onPress={() => {
                  setTime("")
                  setDay(undefined)
                  setDateFromSlotSelection(undefined)
                  setNowSelected(true)
                }}>
                {intl.formatMessage({ id: queueIsOpen === true ? "postpone.now" : "unassign.postpone.when.open" })}
              </Button>

              {typeof queueIsOpen === "string" && (
                <StaffLabelTextS className={styles.queueOpenTime} margin="var(--size-100) var(--size-400)">
                  {queueIsOpen}
                </StaffLabelTextS>
              )}
              {featureFlags.has("postpone_slots_selection") ? (
                <PostponeTimes
                  conversationId={conversationId}
                  setDate={setDateFromSlotSelection}
                  nowSelected={nowSelected}
                  setNowSelected={setNowSelected}
                  selectedQueue={selectedQueue.queueKey}
                />
              ) : (
                <div className={styles.postponeTime}>
                  <TimeInput darkMode={!!time} value={time} onChange={setTime} />

                  <div className={styles.radiobutton}>
                    <RadioBtn
                      label={intl.formatMessage({ id: "unassign.postpone.today" })}
                      checked={day === "today"}
                      onChangeRadio={() => setDay("today")}
                      disabled={!time}
                    />
                  </div>

                  <div className={styles.radiobutton}>
                    <RadioBtn
                      label={intl.formatMessage({ id: "unassign.postpone.tomorrow" })}
                      checked={day === "tomorrow"}
                      onChangeRadio={() => setDay("tomorrow")}
                      disabled={!time}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
      <div className={styles.buttonContainer}>
        <Button variant="secondary" color="primary" onPress={onCancel} fullWidth>
          {intl.formatMessage({ id: "btn.cancel" })}
        </Button>
        <Button variant="primary" color="primary" isDisabled={!selectedQueue} onPress={handleUnassign} fullWidth>
          {intl.formatMessage({ id: "label.send" })}
        </Button>
      </div>
    </>
  )
}

export default ToQueueStaff
