import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { Button } from "@doktor-se/bones-ui"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import { sendSmsReminder } from "api"
import { featureFlags } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { patientStatusSelector } from "reducers/patients/status.reducer"
import { queueSelectors } from "reducers/queue/queue.reducer"

import styles from "./SmsReminderButton.module.scss"

const PUSH_REMINDER_INTERVAL = 15 * 60 * 1000

const SmsReminderButton = ({ conversation }: { conversation: AssignedConversation }) => {
  const intl = useIntl()
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const queues = useAppSelector(queueSelectors.selectAll)
  const patientStatus = useAppSelector(state => patientStatusSelector(state, conversation.patientId))

  useEffect(() => {
    setIsButtonVisible(false)
    if (featureFlags.has("sms_conversation_reminder")) {
      if (conversation.lastQueue && patientStatus === "offline") {
        const queue = queues.find(q => q.queueKey === conversation.lastQueue)
        if (queue && queue.hasReminder && conversation.messages) {
          const lastReminder = conversation.messages
            .filter(msg => msg.type === "conversation_reminder")
            .sort((a, b) => new Date(b.created!).getTime() - new Date(a.created!).getTime())[0]

          const lastPushTime = lastReminder ? new Date(lastReminder.created!).getTime() : 0
          const currentTime = Date.now()
          const timeSinceLastPush = currentTime - lastPushTime

          if (timeSinceLastPush >= PUSH_REMINDER_INTERVAL) {
            setIsButtonVisible(true)
            return
          } else {
            setIsButtonVisible(false)

            const timeoutId = setTimeout(() => setIsButtonVisible(true), PUSH_REMINDER_INTERVAL - timeSinceLastPush)
            return () => clearTimeout(timeoutId)
          }
        }
      }
    }
  }, [conversation, conversation.messages, patientStatus, queues])

  const sendSms = () => {
    dispatch(sendSmsReminder(conversation.id))
  }

  if (!isButtonVisible) {
    return null
  }

  return (
    <div className={styles.reminderButtonContainer}>
      <Button color="primary" variant="primary" onPress={() => sendSms()}>
        {intl.formatMessage({ id: "label.sms_reminder.send" })}
      </Button>
    </div>
  )
}

export default SmsReminderButton
